import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const FooterStyles = styled.footer`
  display: grid;
  place-items: center;
  place-content: center;
  gap: 3rem;
  padding: 3rem 0;
  background-color: var(--darkShade);

  p {
    margin: 0;
    padding: 0;
    font-weight: 800;
    color: var(--lightAccentSmallText);
  }
  a {
    color: var(--lightAccentSmallText);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--darkAccent);
    }
  }
`;

const FooterNavStyles = styled.div``;

const FooterNavListStyles = styled.nav`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 1rem;

  a::after {
    bottom: -1rem;
  }

  a {
    margin: 1rem;
    line-height: 1rem;

    &:hover {
      text-decoration: none;
      color: var(--linkHover);
      &:after {
        bottom: -1rem;
      }
    }
  }
`;

const SiteInfoStyles = styled.div`
  display: grid;
  gap: 1.5rem;
  place-content: center;

  @media (max-width: 800px) {
    place-items: center;
    .contact,
    .copyright {
      display: grid;
      place-items: center;
    }
  }
`;

export default function Footer({ pageContext }) {
  const doNotShowContactForm = process.env.GATSBY_SHOW_CONTACT_FORM === 'false';
  return (
    <FooterStyles>
      <FooterNavStyles>
        <FooterNavListStyles aria-label="footer navigation">
          <Link to="/" title="Home">
            Home
          </Link>
          <Link
            to="/styles"
            title="Home Styles"
            className={
              pageContext.collection === 'style' ? 'current-parent' : null
            }
          >
            House Styles
          </Link>
          <Link
            to="/gallery"
            title="Gallery"
            className={
              pageContext.collection === 'gallery' ? 'current-parent' : null
            }
          >
            Gallery
          </Link>
          <Link to="/about" title="About">
            About
          </Link>
          <Link to="/contact" title="Contact">
            Contact
          </Link>
        </FooterNavListStyles>
      </FooterNavStyles>
      <SiteInfoStyles>
        <div className="contact">
          <p>Mark Shellnut Architect, Inc.</p>
          <p>Oak View, CA 93022</p>
          <p>
            Phone:{' '}
            <a
              data-umami-event="Phone Link Footer"
              target="_blank"
              rel="noreferrer"
              href="tel:+1-805-649-2056"
              title="Call Mark Shellnut"
              aria-label="Mark Shellnut's Phone Number"
            >
              (805) 649-2056
            </a>
          </p>
          {doNotShowContactForm && (
            <p>
              Email:{' '}
              <a
                data-umami-event="Email Link Footer"
                target="_blank"
                rel="noreferrer"
                href="mailto:shellnut@sbcglobal.net?subject=Contact Mark Shellnut"
                title="Email Mark Shellnut"
                aria-label="Mark Shellnut's Email"
              >
                shellnut@sbcglobal.net
              </a>
            </p>
          )}
        </div>
        <div className="copyright">
          <p>California License #C22970</p>
          <p>Copyright &copy; {new Date().getFullYear()} </p>
        </div>
        <div className="created-by">
          <p>
            Created by{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://bradleyshellnut.com"
              title="Bradley Shellnut's Website"
              aria-label="To site author Bradley Shellnut's website"
            >
              Bradley Shellnut
            </a>
          </p>
        </div>
      </SiteInfoStyles>
    </FooterStyles>
  );
}
