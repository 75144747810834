import { createGlobalStyle } from 'styled-components';

import MerriweatherSansLight from '../../static/fonts/Merriweather_Sans/static/MerriweatherSans-Light.ttf';
import MerriweatherSansLightItalic from '../../static/fonts/Merriweather_Sans/static/MerriweatherSans-LightItalic.ttf';
import MerriweatherSansRegular from '../../static/fonts/Merriweather_Sans/static/MerriweatherSans-Regular.ttf';
import MerriweatherSansRegularItalic from '../../static/fonts/Merriweather_Sans/static/MerriweatherSans-Italic.ttf';
import MerriweatherSansBold from '../../static/fonts/Merriweather_Sans/static/MerriweatherSans-Bold.ttf';
import MerriweatherSansBoldItalic from '../../static/fonts/Merriweather_Sans/static/MerriweatherSans-BoldItalic.ttf';

const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'Merriweather Sans';
    src: url(${MerriweatherSansLight}) format('ttf');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Merriweather Sans';
    src: url(${MerriweatherSansLightItalic}) format('ttf');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'Merriweather Sans';
    src: url(${MerriweatherSansRegular}) format('ttf');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Merriweather Sans';
    src: url(${MerriweatherSansRegularItalic}) format('ttf');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'Merriweather Sans';
    src: url(${MerriweatherSansBold}) format('ttf');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Merriweather Sans';
    src: url(${MerriweatherSansBoldItalic}) format('ttf');
    font-weight: 700;
    font-style: italic;
  }
`;
export default Fonts;
