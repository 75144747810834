import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const LogoStyles = styled.div`
  text-transform: uppercase;

  @media (max-width: 800px) {
    margin-left: 0.5rem;
  }

  a {
    font-weight: bold;
    margin-right: 0;
    padding: 0;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--secondary);
    }

    h1 {
      color: var(--secondary);
      letter-spacing: 0.25rem;
      font-size: 3rem;
      font-size: clamp(27px, 2.5vw, 40px);
      margin-top: 2rem;
      line-height: 2.5rem;

      @media (max-width: 1000px) {
        margin-top: 1.3rem;
        line-height: 2.8rem;
      }
    }
    h2 {
      color: var(--black);
      font-size: 2rem;
      font-size: clamp(18px, 2vw, 30px);
      margin-top: 1.5rem;
      margin-bottom: 2rem;

      @media (max-width: 1000px) {
        margin-top: 0.5rem;
        margin-bottom: 1.3rem;
      }

      @media (max-width: 700px) {
        margin-bottom: 0.5rem;
        line-height: 2.5rem;
      }
    }
  }
`;

export default function Logo() {
  return (
    <LogoStyles>
      <Link to="/" className="center">
        <h1>Mark Shellnut</h1>
        <h2>Architect, Inc.</h2>
      </Link>
    </LogoStyles>
  );
}
