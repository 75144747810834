import styled from 'styled-components';

const LayoutStyles = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;

  p {
    word-wrap: anywhere;
  }
`;

export default LayoutStyles;
