import React from 'react';
import styled from 'styled-components';
import 'normalize.css';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import LayoutStyles from '../styles/LayoutStyles';
import Header from './Header';
import Footer from './Footer';
import Fonts from './Fonts';

const ContentStyles = styled.main`
  max-width: 850px;
  margin: 0 auto;
  padding: 3rem 0rem;

  @media (max-width: 850px) {
    max-width: 80%;
  }
`;

export default function Layout({ children, pageContext }) {
  return (
    <>
      <GlobalStyles />
      <Fonts />
      <Typography />
      <LayoutStyles>
        <Header pageContext={pageContext} />
        <ContentStyles>{children}</ContentStyles>
        <Footer pageContext={pageContext} />
      </LayoutStyles>
    </>
  );
}
