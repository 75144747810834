import { createGlobalStyle } from 'styled-components';
import floatingCogs from '../assets/images/floating-cogs.svg';

const GlobalStyles = createGlobalStyle`
  :root {
    --red: #990000;
    --redBrown: #633539;
    --blue: #336699;
    --black: #1f273a;
    --white: #fff;
    --grey: #efefef;
    --greyBlue: #888e9c;
    --lighterGreyBlue: #6a707e;
    --yellow: #ffc600;
    --light: #ffffff;
    --dark: #000000;
    --lightGrey: #C5C5C5;
    --lightGray: var(--lightGrey);
    --imGoingToFaint: #fbfbfb;
    --redBrown: hsl(355deg 30% 30%);
    --lighterBrown: hsl(355deg 30% 45%);
    --maxWidth: 1200px;

    --mainBrand: #888e9c;
    --lightShade: #f8f7f5;
    --lightAccent: #ad876e;
    --lightAccentSmallText: #f5cbb0;
    --darkAccent: #633539;
    --darkShade: #525a6f;
    --smallText: #1f273a;

    --oldmainBrand: #a7adbb;
    --oldlightShade: #f8f7f5;
    --oldlightAccent: #b99379;
    --olddarkAccent: #a67275;
    --olddarkShade: #61697f;

    /* Define Colors intentions */
    --primary: var(--greyBlue);
    --secondary: var(--redBrown);
    --background: var(--white);
    --textColor: var(--lighterGreyBlue);
    --buttonTextColor: var(--black);
    --lineColor: var(--grey);
    --cardBg: var(--darkGrey);
    --headerBackground: var(--greyBlue);
    --footerBackground: var(--darkGrey);
    --linkHover: var(--white);
    --lightHairLine: #C5C5C5;
    
    /* Modal */
    --modalBackground: var(--background);

    /* Styles */
    --line: solid 1px var(--lineColor);

    /* Type */
    --headingFont: 'Merriweather Sans', sans-serif;
    --bodyFont: 'Work Sans', sans-serif;
    --baseFontSize: 100%;
    --h1: 3.052rem;
    --h2: 2.441rem;
    --h3: 1.953rem;
    --h4: 1.563rem;
    --h5: 1.25rem;
    --h6: 1.8rem;
    --bodyTextSize: 1.8rem;
    --smallText: 1.44rem;
    --lineHeight: 2.25rem;

    /* Elevation */
    --level-0: none;
    --level-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --level-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --level-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    --level-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);

    /* Z Indexes */
    --zBase: 1;

    /* Positioning */
    --containerPadding: 2.5%;
    --headerHeight: 8rem;
    --borderRadius: 4px;
    --borderRadiusLarge: 12px;
    --maxWidth: 1200px;

    /* 3D Button Timings */
    --buttonHoverTime: 250ms;
    --buttonPushTime: 34ms;
    --buttonTime: 600ms;

    @media (prefers-reduced-motion) {
      --buttonHoverTime: 450ms;
      --buttonPushTime: 54ms;
      --buttonTime: 750ms;
    }

    /* Media Queryies - Not yet supported in CSS */
    /* 
      --xsmall: 340px;
      --small: 500px;
      --large: 960px;
      --wide: 1200px;
    */
  }
  
  html {
    /* background-image: url(${floatingCogs}); */
    background-color: var(--background);
    background-size: 450px;
    background-attachment: fixed;
    font-size: 62.5%;
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  
  *:focus {
    outline-color: var(--yellow);
  }

  body {
    line-height: var(--lineHeight);
    font-size: 2rem;
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }

  html {
    scrollbar-width: thin;
    scrollbar-color: var(--primary) var(--white);
  }

  body::-webkit-scrollbar-track {
    background: var(--white);
  }

  body::-webkit-scrollbar-thumb {
    background-color: var(--primary) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  ::selection {
    background: var(--primary);
    color: var(--white);
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  /* a.button,
  button {
    -webkit-appearance: inherit;
    background: var(--secondary);
    color: var(--white);
    border: 0;
    padding: 0.6rem 1rem;
    border-radius: var(--borderRadius);
    cursor: pointer;
    --cast: 2px;
    box-shadow: var(--cast) var(--cast) 0 var(--lightAccent);
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,0.2);
    transition: all 0.2s;
    text-decoration: none;
    &:hover {
      --cast: 3px;
    }
    &:active {
      --cast: 0px;
    }
  } */

  /* a:hover {
    color: var(--linkHover);
  } */

  input,textarea {
    font-size: 1.5rem;
    line-height: 1.5rem;
    padding: 0.4rem;
  }

  textarea {
    resize: vertical;
  }

  hr {
    border: 0;
    height: 8px;
  }

  nav {
    a::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: -5px;
      left: 0px;
      background: var(--secondary);
      transition: transform 0.3s ease 0s;
      transform: scale(0);
    }

    a {
      position: relative;
      font-size: 2rem;
      text-decoration: none;

      &[aria-current='page'],
      &.current-parent {
        &:after {
          transform: scale(1);
        }
      }

      &:hover {
        color: var(--linkHover);
        &:after {
          transform: scale(1);
        }
      }
    }
  }

  img {
    max-width: 100%;
  }

  img,
  figure {
    border-radius: var(--borderRadius);
    box-shadow: var(--level-2);
  }

  .card {
    padding: 1.5rem;
    background: var(--cardBg);
    box-shadow: var(--level-3);
    border-radius: var(--borderRadius);
  }

  /* First item will never have margin top */
  .card > *:first-child {
    margin-top: 0;
  }

  /* Last item will never have margin bottom */
  .card > *:last-child {
    margin-bottom: 0;
  }

  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: var(--secondary);
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;

    width: 100%;
    height: 5px;
  }

  /* Fancy blur effect */
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px var(--secondary), 0 0 5px var(--secondary);
    opacity: 1;

    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
  }

  /* Remove these to get rid of the spinner */
  #nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px;
  }

  #nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;

    border: solid 2px transparent;
    border-top-color: var(--secondary);
    border-left-color: var(--secondary);
    border-radius: 50%;

    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
  }

  .nprogress-custom-parent {
    overflow: hidden;
    position: relative;
  }

  .nprogress-custom-parent #nprogress .spinner,
  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }

  @-webkit-keyframes nprogress-spinner {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes nprogress-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* 3D Button Styles */
  .pushable {
    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    transition: filter var(--buttonHoverTime);
  }

  .icon {
    display: flex;
    gap: 1rem;
    text-decoration: none;
    color: white;
  }
    
  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: hsl(0deg 0% 0% / 0.25);
    will-change: transform;
    transform: translateY(2px);
    transition:
      transform
      var(--buttonTime)
      cubic-bezier(.3, .7, .4, 1);
  }
  .edge {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: linear-gradient(
      to left,
      hsl(340deg 100% 14%) 0%,
      hsl(355deg 30% 30%) 8%,
      hsl(355deg 30% 30%) 92%,
      hsl(340deg 100% 18%) 100%
    );
  }
  .front {
    display: block;
    position: relative;
    padding: 12px 42px;
    border-radius: 12px;
    font-size: 1.25rem;
    color: white;
    background: var(--lighterBrown);
    will-change: transform;
    transform: translateY(-4px);
    transition:
      transform
      var(--buttonTime)
      cubic-bezier(.3, .7, .4, 1);
  }
  .pushable:hover {
    filter: brightness(110%);
  }
  .pushable:hover .front {
    transform: translateY(-6px);
    transition:
      transform
      var(--buttonHoverTime)
      cubic-bezier(.3, .7, .4, 1.5);
  }
  .pushable:active .front {
    transform: translateY(-2px);
    transition: transform var(--buttonPushTime);
  }
  .pushable:hover .shadow {
    transform: translateY(4px);
    transition:
      transform
      var(--buttonHoverTime)
      cubic-bezier(.3, .7, .4, 1.5);
  }
  .pushable:active .shadow {
    transform: translateY(1px);
    transition: transform var(--buttonTime);
  }
  .pushable:focus:not(:focus-visible) {
    outline: none;
  }
`;

export default GlobalStyles;
