import React from 'react';
import styled from 'styled-components';
import Logo from './Logo';
import Nav from './Nav';

const HeaderStyles = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.8rem;
  background: var(--headerBackground);
  box-shadow: var(--level-2);
  padding: 0 var(--containerPadding);

  @media (max-width: 650px) {
    display: grid;
    grid-template-columns: 1fr;
    align-content: center;
    justify-items: center;
    padding: var(--containerPadding);
  }
`;

export default function Header({ pageContext }) {
  return (
    <HeaderStyles>
      <Logo />
      <Nav pageContext={pageContext} />
    </HeaderStyles>
  );
}
