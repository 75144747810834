import { createGlobalStyle } from 'styled-components';

import merriweatherSans from '../../static/fonts/Merriweather_Sans/static/MerriweatherSans-Regular.ttf';
import monda from '../../static/fonts/Monda/Monda-Regular.ttf';
import padauk from '../../static/fonts/Padauk/Padauk-Regular.ttf';
import workSans from '../../static/fonts/Work_Sans/static/WorkSans-Medium.ttf';

const Typography = createGlobalStyle`
  @font-face {
    font-family: Merriweather Sans;
    src: url(${merriweatherSans});
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: Monda;
    src: url(${monda});
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: Padauk;
    src: url(${padauk});
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: Work Sans;
    src: url(${workSans});
    font-weight: 600;
    font-style: normal;
  }

  html {
    font-family: var(--bodyFont), -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    color: var(--black);
  }

  p, li {
    letter-spacing: 0.5px;
    font-size: 1.5rem;
  }

  h1,h2,h3,h4,h5,h6 {
    font-family: var(--headingFont);
    font-weight: 400;
    margin: 0;
    color: var(--primary);
  }

  h1 {
    margin-top: 0;
    font-size: var(--h1);
  }

  h2 {
    font-size: var(--h2);
  }

  h3 {
    font-size: var(--h3);
    color: var(--textColor);
  }

  h4 {
    font-size: var(--h4);
    color: var(--textColor);
  }

  h5 {
    font-size: var(--h5);
    color: var(--textColor);
  }

  body {
    font-family: var(--bodyFont);
  }

  nav {
    font-family: var(--headingFont);
  }

  a {
    color: var(--black);
  }

  .center {
    text-align: center;
  }
`;

export default Typography;
