import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const NavUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 2rem;
  justify-content: space-evenly;
`;

const NavLi = styled.li`
  display: grid;
  grid-template-columns: 1fr auto;
  position: relative;
  align-items: start;
  align-content: center;
  margin: 1rem;

  a {
    font-size: 2.5rem;
    color: var(--smallText);
    text-decoration: none;
    line-height: 1rem;
    &[aria-current='page'],
    &.current-parent {
      &:after {
        transform: scale(1);
      }
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 0.2rem;
      bottom: 0rem;
      @media (max-width: 1000px) {
        bottom: -0.5rem;
      }
      left: 0px;
      background: var(--darkAccent);
      transition: transform 0.3s ease 0s;
      transform: scale(0);
    }

    &:hover {
      color: var(--white);
      &:after {
        transform: scale(1);
      }
    }
    @media (max-width: 1000px) {
      font-size: 2rem;
    }
    @media (max-width: 800px) {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }
  @media (max-width: 580px) {
    grid-template-columns: 1fr;
    margin: 0;
    text-align: center;
  }
`;

const NavStyles = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  align-content: center;
  gap: 1.5rem;

  a + a {
    margin-left: 25px;
  }
  margin: 0.5rem;
  padding: 2rem;

  @media (max-width: 1000px) {
    justify-content: center;
    a + a {
      margin-left: 0;
    }
  }

  @media (max-width: 650px) {
    justify-content: center;
    font-size: 1.5rem;
    padding: 0 10px;
  }
`;

export default function Nav({ pageContext }) {
  return (
    <NavStyles>
      <Link to="/" title="Home">
        Home
      </Link>
      <Link
        to="/styles"
        title="Home Styles"
        className={pageContext.collection === 'style' ? 'current-parent' : null}
      >
        House Styles
      </Link>
      <Link
        to="/gallery"
        title="Gallery"
        className={
          pageContext.collection === 'gallery' ? 'current-parent' : null
        }
      >
        Gallery
      </Link>
      <Link to="/about" title="About">
        About
      </Link>
      <Link to="/contact" title="Contact">
        Contact
      </Link>
    </NavStyles>
  );
}
